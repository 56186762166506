<template>
  <a-drawer title="上传签章" placement="right" destroyOnClose :visible="visible" @close="closeModal"
    width="50%">
    <ax-form ref="formBuilder" :formBuilder="formBuilder">
      <template slot="file">
        <div class="clearfix">
          <a-upload list-type="picture-card" :file-list="fileList" @preview="handlePreview"
            @change="handleChange" :before-upload="beforeUpload">
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </div>
      </template>
    </ax-form>
    <a-modal :visible="previewVisible" width="50%" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <div style="padding: 10px 0;"></div>
    <div :style="{ position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9',
        padding: '10px 16px', background: '#fff', textAlign: 'right' }">
      <a-button :style="{marginRight: '8px'}" @click="closeModal">取消</a-button>
      <a-button @click="save" type="primary" :loading="loading">确认</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { initGridFormData } from '@/common/tools'
import api from './user'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  props: {
    autographType: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      visible: false,
      uploadData: [],
      record: {},
      formBuilder: initGridFormData([
        {
          label: '签章图片',
          type: '',
          model: 'file',
          options: {},
          formItem: {},
          rules: [{ required: true, message: '签章图片不能为空' }],
          col: { span: 24 }
        },
        {
          label: '批次',
          type: 'inputNumber',
          model: 'batch',
          options: {
            defaultValue: 1
          },
          formItem: {},
          col: { span: 24 },
          rules: [{ required: true, message: '批次不能为空' }]
        }
        // {
        //   label: '图片类型',
        //   type: 'select',
        //   model: 'imageType',
        //   options: { options: [{ label: '九宫格', value: '1' }, { label: '十六宫格', value: '2' }], defaultValue: '1' },
        //   formItem: {},
        //   rules: [{ required: true, message: '图片类型不能为空' }],
        //   col: { span: 24 }
        // }
      ]),
      previewVisible: false,
      previewImage: '',
      fileList: [],
      loading: false
    }
  },
  methods: {
    openModal (record) {
      this.visible = true
    },

    closeModal () {
      this.visible = false
      this.fileList = []
    },
    save () {
      if (this.fileList.length <= 0) {
        this.$message.warning('请上传图片信息')
        return
      }
      this.$refs.formBuilder.form.validateFields(async (errors, values) => {
        if (!errors) {
          this.loading = true
          const formData = new FormData()
          formData.append('file', this.fileList[0].originFileObj)
          formData.append('batch', values.batch)
          // formData.append('imageType', values.imageType)
          formData.append('autographType', this.autographType)
          api.fileCutImage(formData).then(res => {
            this.$emit('closeModal')
            this.closeModal()
            setTimeout(() => {
              this.loading = false
            }, 100)
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    beforeUpload (file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList }) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
</style>
