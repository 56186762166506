import { render, staticRenderFns } from "./viewSignature.vue?vue&type=template&id=44b9b70e&scoped=true"
import script from "./viewSignature.vue?vue&type=script&lang=js"
export * from "./viewSignature.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b9b70e",
  null
  
)

export default component.exports