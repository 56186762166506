<template>
  <a-drawer title="查看签章" placement="right" destroyOnClose :visible="visible" @close="closeModal"
    width="50%">
    <a-list :grid="{ gutter: 16, column: 2 }" :data-source="autographUrlList" >
      <a-list-item slot="renderItem" slot-scope="item">
        <a-card :title="`批次${item.batch}`" @click="handleClick(item)" :bodyStyle="{ height: '400px' }">
          <img :src="item.autographUrl" alt="" style="width:100%;height: 100%;">
        </a-card>
      </a-list-item>
    </a-list>
    <a-modal v-model="visibleModal" title="签章" :footer="null" width="50%">
      <img style="height:100%;width:100%;" :src="autographUrl">
    </a-modal>
  </a-drawer>
</template>
<script>
import api from './user'

export default {
  props: {
    autographType: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      visible: false,
      autographUrlList: [],
      visibleModal: false,
      autographUrl: ''
    }
  },

  mounted () {

  },

  methods: {
    openModal (record) {
      this.visible = true
      this.getList()
    },

    closeModal () {
      this.visible = false
    },

    getList () {
      api.signatureList({ pageSize: 10000, isMainDiagram: '1', autographType: this.autographType }).then(res => {
        this.autographUrlList = res.data.records
      })
    },

    handleClick (item) {
      this.autographUrl = item.autographUrl
      this.visibleModal = true
    }
  }
}
</script>
<style lang="less" scoped>
</style>
