import { get, post } from 'common/request'

export default {
  roleList: args => get('/role/queryList', args, 'portal'),
  listCheck: args => get('/user/queryList', args),
  queryDepartTreeList: (params) => get('/dept/treeList', params),
  searchByKeywords: (params) => get('/dept/searchBy', params, 'portal'),
  // 上传签章
  fileCutImage: (params) => post('/file/cutImage', params),
  // 获取签章列表
  signatureList: (params) => get('/signature/list', params),
  // 编辑用户
  editUser: (params) => post('/user/updateSignature', params),
  // 查看批次
  signatureQeuryBatch: (params) => get('/signature/qeuryBatch', params),
  // 查部门
  deptQueryTree: (params) => get('/dept/insideListToPage', params),
  // 编辑部门
  deptUpdate: params => post('/dept/update', params),
  // 编辑图片
  updateAutograph: params => post('/signature/updateAutograph', params),
  // 编辑部门
  updateDeptAutograph: params => post('/signature/updateDeptAutograph', params)
}
