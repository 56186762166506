<template>
  <div>
    <a-drawer title="选择签章" placement="right" destroyOnClose :visible="visible" @close="closeModal"
      width="50%">
      <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 6 }">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="批次">
              <a-select v-decorator="[
          'batch',
        ]" placeholder="请选择批次" allowClear @change="handleSelectChange">
                <a-select-option :value="item.value" v-for="(item, index) in signatureBatch"
                  :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-upload :before-upload="beforeUpload">
              <a-button type="primary">
                <a-icon type="upload" /> 上传图片
              </a-button>
            </a-upload>
          </a-col>
        </a-row>
      </a-form>
      <a-list :grid="{ gutter: 16, column: 3 }" :data-source="autographUrlList">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="btn btn-default" @click="handleClick(item)"
            :class="{ active: item.id === imgData.id }">
            <a-card :bodyStyle="{padding: 0}">
              <img :src="item.autographUrl" alt="" style="height:100%;width:100%;">
            </a-card>
          </div>
        </a-list-item>
      </a-list>
      <div style="padding: 10px 0;"></div>
      <div :style="{ position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9',
        padding: '10px 16px', background: '#fff', textAlign: 'right' }">
        <a-button :style="{marginRight: '8px'}" @click="closeModal">取消</a-button>
        <a-button @click="save" type="primary">确认</a-button>
      </div>
    </a-drawer>

    <selectSignature ref="selectSignature" autographType="1"
      :selectApi="selectApi"
      @closeModal="selectSignatureCloseModal" />
  </div>
</template>
<script>
import api from './user'
import selectSignature from './selectSignature.vue'

export default {
  components: {
    selectSignature
  },
  props: {
    autographType: {
      type: String,
      default: '1'
    },
    editApi: {
      type: Function,
      default: () => {}
    },
    selectApi: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      visible: false,
      autographUrlList: [],
      imgData: {},
      form: this.$form.createForm(this),
      record: {},
      signatureBatch: [],
      fileList: []
    }
  },

  mounted () {

  },

  methods: {
    openModal (record) {
      this.visible = true
      this.record = { ...record }
      this.getList()
      this.signatureQeuryBatch()
    },

    closeModal () {
      this.visible = false
    },

    getList (params = {}) {
      api.signatureList({ pageSize: 10000, isMainDiagram: '2', autographType: this.autographType, ...params }).then(res => {
        this.autographUrlList = res.data.records
      })
    },

    // 获取批次
    signatureQeuryBatch () {
      api.signatureQeuryBatch({ autographType: this.autographType }).then(res => {
        this.signatureBatch = res.data
      })
    },

    handleClick (item) {
      this.imgData = item
    },

    handleSelectChange (value) {
      this.getList({ batch: value || '' })
    },

    save () {
      if (!this.imgData.id) {
        this.$message.warning('请选择签章')
        return
      }
      this.editApi({ ...this.record, autographUrl: this.imgData?.autographUrl, roleId: this.record?.roleId?.split(',') }).then(res => {
        this.$message.success('操作成功')
        this.$emit('closeModal')
        this.closeModal()
      })
    },

    handleUpload (file) {
      this.closeModal()
      this.$refs.selectSignature.openModal(file, this.record)
    },

    beforeUpload (file) {
      this.handleUpload(file)
      return false
    },

    selectSignatureCloseModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
<style lang="less" scoped>
.active {
  background-color: lightseagreen !important;
  border: 2px solid lightseagreen !important;
  filter: brightness(150%);
}
.btn-default:focus,
.btn-default:hover {
  background-color: lightseagreen;
  border: 2px solid lightseagreen;
  filter: brightness(150%);
}
.btn {
  background: #fff;
  border: 2px solid #fff;
}
</style>
