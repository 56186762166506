<template>
  <div class="_page-content">
    <a-row :gutter="20">
      <a-col :md="5" :sm="24">
        <a-alert type="info" :showIcon="true">
          <div slot="message">
            当前选择：
            <span v-if="currSelected.title" style="color:black">{{ getCurrSelectedTitle() }}</span>
            <a v-if="currSelected.title" @click="onClearSelected"
              style="margin-left: 10px;color:#3853db">取消选择</a>
          </div>
        </a-alert>
        <a-input-search @search="onSearch" style="width:100%;margin-top: 10px"
          placeholder="请输入部门名称" />
        <!-- 树-->
        <a-dropdown :trigger="[this.dropTrigger]" @visibleChange="dropStatus">
          <a-tree style="user-select: none;height: 50vh;overflow-y: auto;" multiple
            @select="onSelect" @check="onCheck" :selectedKeys="selectedKeys"
            :checkedKeys="checkedKeys" :treeData="departTree" :load-data="onLoadData"
            :checkStrictly="true" :expandedKeys="iExpandedKeys" :autoExpandParent="autoExpandParent"
            @expand="onExpand" />
        </a-dropdown>
      </a-col>
      <a-col :md="19" :sm="24">
        <ax-table ref="tableBox" :show-search="true" :searchForm="searchForm"
          :searchActions='searchActions' :columns="columns" :dataSourceApi="api.listCheck"
          :autoLoad="false" :sqlParams="sqlParams" :dataSourceParams="dataSourceParams"
          :clickable="false" @action-column-click="actionColumnClick" @add="handleAdd"
          :scroll="{ y: '55vh', x: '80vw' }"
          @on-search-click="onSearchClick">
          <template #autographUrl="{record}">
            <div class="table-ellipsis" style="max-height: 100px;">
              <img style="height:100%;width:100%;" @click="show(record.autographUrl)"
                :src="record.autographUrl">
            </div>
          </template>
        </ax-table>
      </a-col>
    </a-row>

    <uploadSignature ref="uploadSignature" @closeModal="handleCloseModal" autographType="1" />
    <a-modal v-model="visible" title="签章" :footer="null" width="50%">
      <img style="height:100%;width:100%;" :src="autographUrl">
    </a-modal>

    <editSignature ref="editSignature" :editApi="api.editUser" :selectApi="api.updateAutograph"
    @closeModal="handleCloseModal"
      autographType="1" />

    <viewSignature ref="viewSignature" @closeModal="handleCloseModal" autographType="1" />
  </div>
</template>

<script>
import api from './user'
import uploadSignature from './uploadSignature.vue'
import editSignature from './editSignature.vue'
import viewSignature from './viewSignature.vue'

export default {
  name: 'TestAxTable',
  components: {
    uploadSignature,
    editSignature,
    viewSignature
  },
  data () {
    return {
      api,
      visible: false,
      dataSourceParams: {
      },
      autographUrl: '',
      // 树形部分
      departTree: [],
      departTreeCopy: [],
      currSelected: {},
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      iExpandedKeys: [],
      dropTrigger: '',
      columns: this.$ax.tools.initColumn([
        {
          title: '用户名',
          dataIndex: 'username',
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'realname',
          ellipsis: true
        },
        {
          title: '签章',
          dataIndex: 'autographUrl'
        }
      ], true, {
        width: 150,
        actions: [
          { props: record => { return { text: '编辑', name: 'handleEdit', type: '#3853db', link: true, loading: record.id === 0 } } }
        ]
      }),
      searchActions: [
        { name: 'add', text: '上传签章' },
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'view', text: '查看签章' }
      ],
      sqlParams: {
        like: ['realname', 'username', 'roleId']
      },
      searchForm: this.$common.initGridFormData(
        [
          { label: '用户名', type: '', model: 'username', options: [], col: { span: 8 } },
          { label: '姓名', type: '', model: 'realname', options: [], col: { span: 8 } }
        ])
    }
  },
  async created () {
    this.loadTree()
    const un = await api.roleList()
    const options = []
    un.data.records.map(item => {
      const data = {
        label: item.roleName,
        value: item.id
      }
      options.push(data)
    })
    this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp('roleId', { options: { options } })
    this.getList()
  },
  methods: {
    show (autographUrl) {
      this.autographUrl = autographUrl
      this.visible = true
    },
    // 树形部分代码  当前选择显示
    getCurrSelectedTitle () {
      return !this.currSelected.title ? '' : this.currSelected.title
    },
    onClearSelected () {
      this.checkedKeys = []
      this.currSelected = {}
      this.selectedKeys = []
      this.dataSourceParams.deptId = ''
      this.getList()
    },
    getList () {
      this.$refs.tableBox.getDataSource()
    },
    actionColumnClick (args) {
      const { btn: { name }, record } = args
      switch (name) {
        case 'handleEdit':
          this.$refs.editSignature.openModal(record)
          break
      }
    },
    onSearchClick ({ btn, formValues }) {
      switch (btn.name) {
        case 'view':
          this.$refs.viewSignature.openModal()
          break
      }
    },
    onSearch (value) {
      const that = this
      if (value) {
        api.searchByKeywords({ keyWord: value }).then((res) => {
          that.departTree = []
          for (let i = 0; i < res.data.length; i++) {
            const temp = res.data[i]
            that.departTree.push(temp)
          }
        })
      } else {
        this.iExpandedKeys = []
        this.loading = true
        this.departTree = this.departTreeCopy
        this.loading = false
      }
    },
    loadTree () {
      this.$nextTick(() => {
        api.queryDepartTreeList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
          this.departTreeCopy = this.departTree
        })
      })
    },
    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          api.queryDepartTreeList({ parentId: treeNode.dataRef.id }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            } else {
              // this.$message.warning('该部门下没有子部门！')
              treeNode.dataRef.leaf = true
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.departTree = [...this.departTree]
          resolve()
        }, 1000)
      })
    },
    onSelect (selectedKeys, e) {
      const record = e.node.dataRef
      this.currSelected = Object.assign({}, record)
      this.selectedKeys = [record.key]
      this.dataSourceParams.deptId = record.id
      this.$refs.tableBox.pagination.current = 1
      this.$refs.tableBox.getDataSource()
      this.setValuesToForm(record)
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm (record) {
      this.pager.pageNo = record.total
    },
    onCheck (checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked
    },
    onExpand (expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 右键点击下拉框改变事件
    dropStatus (visible) {
      if (visible === false) {
        this.dropTrigger = ''
      }
    },
    // 添加用户
    handleAdd: function () {
      this.$refs.uploadSignature.openModal()
    },
    handleEdit: function ({ record }) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.disableSubmit = false
    },
    //
    handleCloseModal () {
      this.$refs.tableBox.getDataSource()
    }
  }
}
</script>
